import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MiddlewareService } from './../../services/middleware.service';
import { Store } from '@ngrx/store';
import * as LimitsActions from './limits.actions';
import { map, catchError, concatMap, debounceTime } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { globalFailure } from '../common.actions';
import { asyncScheduler, of } from 'rxjs';

@Injectable()
export class LimitsEffects {
  loadLimits$ = createEffect(() => ({ debounce = 100, scheduler = asyncScheduler } = {}) => {
    return this.actions$.pipe(
      ofType(LimitsActions.loadLimits),
      debounceTime(debounce, scheduler),
      concatMap(action => {
        const params = new HttpParams();

        return this.middleware.get('/v3/contracts/current/limits', params).pipe(
          map((result: any) =>
            LimitsActions.loadLimitsSuccess({
              data: result.body ? result.body : {}
            })
          ),
          catchError(error => of(LimitsActions.loadLimitsFailure({ error }), globalFailure({ error })))
        );
      })
    );
  });

  constructor(private actions$: Actions, private middleware: MiddlewareService, private readonly store: Store) { }
}
