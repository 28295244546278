import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import * as PricesActions from './prices.actions';
import { concatMap, map, catchError, switchMap, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { combineLatest, of } from 'rxjs';
import { globalFailure } from '../common.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { Store } from '@ngrx/store';
import { getCurrentContractUuid, getCurrentProjectUuid } from '../session/session.selectors';

@Injectable()
export class PricesEffects {
  loadPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricesActions.loadPrices),
      switchMap(action => combineLatest([
        this.store.select(getCurrentContractUuid),
        this.store.select(getCurrentProjectUuid)
      ]).pipe(
        filter(([contractUuid, projectUuid]) => contractUuid !== undefined && projectUuid !== undefined)
      )),
      concatMap(([contractUuid, projectUuid]) => {
        return this.middleware.get('/management/v2/contracts/' + contractUuid + '/projects/' + projectUuid + '/prices').pipe(
          map((result: any) =>
            PricesActions.loadPricesSuccess({
              result:
              {
                ..._.find(result.data, d => d.attributes?.prices !== undefined)?.attributes,
                template_name: _.find(result.data, d => d.relationships?.price_list !== undefined)?.relationships?.price_list?.data?.name,
              }
            })
          ),
          catchError(error => of(PricesActions.loadPricesFailure({ error }), globalFailure({ error })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private middleware: MiddlewareService, private readonly store: Store) { }
}
