import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isLoggingInWithOneTimeTokenDone } from '../store/session/session.selectors';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  constructor(private readonly _authService: AuthService, private readonly _router: Router, private readonly store: Store) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(observer => {
      let waitForOneTimeLogin: Observable<any>;

      const urlParams = new URLSearchParams(document.location.search);
      if (urlParams.has('oneTimeToken')) {
        // if the url parameter is present, we wait for the process to be done before we check the session
        waitForOneTimeLogin = this.store.select(isLoggingInWithOneTimeTokenDone);
      } else {
        waitForOneTimeLogin = of(true);
      }


      waitForOneTimeLogin.pipe(
        filter(done => !!done),
        take(1),
        switchMap(done => this._authService.isLoggedInSessionless()),
        first()
      ).subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn === true) {
          if (state.url.match(/\/error/)) {
            // error page should always be accessible
            observer.next(true);
            return;
          }
          if (state.url.match(/\/invite\/[0-9a-z-]/)) {
            this._authService
              .logout()
              .pipe(take(1))
              .subscribe(() => {
                observer.next(true);
              });
          } else {
            this._router.navigate(['/']);
          }
        }

        if (!isLoggedIn || !state.url.match(/\/invite\/[0-9a-z-]/)) {
          observer.next(!isLoggedIn);
        }
      });
    });
  }
}
