import { concatMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import * as SupportTicketActions from './support-ticket.actions';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCoreApiEndpint } from '../config/config.selectors';

@Injectable()
export class SupportTicketEffects {
  constructor(private readonly actions$: Actions, private readonly http: HttpClient, private store: Store) {}

  getSupportTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportTicketActions.getSupportTicket),
      withLatestFrom(this.store.select(getCoreApiEndpint)),
      concatMap(([action, endpoint]) => {

        return this.http.get(endpoint + '/validate/'  +action.ticket_uuid).pipe(
        map((result: any) => {
          return SupportTicketActions.getSupportTicketSuccess({ data: result , ticket_uuid : action.ticket_uuid });
        }),
        catchError(error => of(SupportTicketActions.getSupportTicketFailure({ error, ticket_uuid : action.ticket_uuid })))
      )}

      )
    )
  );


  validateSupportTicket$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SupportTicketActions.validateSupportTicket),
    withLatestFrom(this.store.select(getCoreApiEndpint)),
    concatMap(([action, endpoint]) => {

      return this.http.post(endpoint + '/validate/'  +action.ticket_uuid, action.data).pipe(
        map((result: any) => {
        return SupportTicketActions.validateSupportTicketSuccess({ data: result , ticket_uuid : action.ticket_uuid });
      }),
      catchError(error => of(SupportTicketActions.validateSupportTicketFailure({ error, ticket_uuid : action.ticket_uuid  })))
    )}

    )
  )
);

}
