import { createReducer, on } from '@ngrx/store';
import * as LocationIpActions from './ip.actions';
import { HttpErrorResponse } from '@angular/common/http';

import { Ip, RequestOptions, Meta, Links, IpsGetResponse } from '@gridscale/gsclient-js';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';

export const locationIpFeatureKey = 'locationIp';

export const adapter: EntityAdapter<Ip> = createEntityAdapter<Ip>({
  selectId: (a => a.object_uuid!)!, // define "primary id"
  sortComparer: false
});

export const listAdapter: EntityAdapter<Ip> = createEntityAdapter<Ip>({
  selectId: (a => a.object_uuid!)!, // define "primary id"
  sortComparer: false
});

const listAdapterInitialState = listAdapter.getInitialState({
  loaded: false,
  loading: false,
  loadingNext: false
}) as ListState;

export interface ListState extends EntityState<Ip> {
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: Links<IpsGetResponse>;
  error?: Error | HttpErrorResponse | undefined;
}

export interface SubState extends EntityState<Ip> {
  list: ListState;
}

export const adapterInitialState = adapter.getInitialState({
  list: listAdapterInitialState
}) as SubState;

export interface State {
  locationIp: {
    [location_uuid: string]: SubState;
  };
  listConfig: RequestOptions;
}
export const initialState: State = {
  locationIp: {},

  listConfig: {
    limit: 10,
    sort: '-name',
    fields: []
  }
};

export const reducer = createReducer(
  initialState,

  on(LocationIpActions.setListConfig, (state, action) => ({
    // update list config / empty collection
    ...state,

    listConfig: {
      ...action.config
    },
    locationIp: _.mapValues(_.get(state, ['locationIp'], {}), (_locationIp: any) => ({
      ..._locationIp,
      list: listAdapterInitialState
    }))
  })),

  on(LocationIpActions.loadLocationIps, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: true
        }
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.setAll(_.toArray(action.result), {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          meta: action.meta,
          links: action.links,
          loading: false,
          loaded: true,
          error: undefined
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsFailure, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.removeAll({
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loaded: true,
          error: action.error
        })
      }
    }
  })),

  on(LocationIpActions.updateLocationIps, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], adapterInitialState.list),
          loading: true
        }
      }
    }
  })),

  on(LocationIpActions.updateLocationIpsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.setAll(_.toArray(action.result), {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          meta: action.meta,
          loading: false,
          loaded: true,
          error: undefined
        })
      }
    }
  })),

  on(LocationIpActions.updateLocationIpsFailure, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], {}),
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.removeAll({
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loaded: true,
          error: action.error
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsNext, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: _.get(state, ['locationIp', action.location_uuid, 'list', 'links', 'next']) !== undefined ? true : false,
          loadingNext: _.get(state, ['locationIp', action.location_uuid, 'list', 'links', 'next']) !== undefined ? true : false
        }
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsNextSuccess, (state: State, action) => ({
    // upsert entities when next page loaded...
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.upsertMany(_.toArray(action.result), {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          meta: action.meta,
          links: action.links,
          loading: false,
          loadingNext: false,
          loaded: true,
          error: undefined
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsNextFailure, (state, action) => ({
    ...state,

    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loadingNext: false,
          loaded: true,
          error: action.error
        }
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocation, (state, action) => state),

  on(LocationIpActions.loadLocationIpsForSelectedLocationStart, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: true
        }
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocationSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.setAll(_.toArray(action.result), {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          meta: action.meta,
          links: action.links,
          loading: false,
          loaded: true,
          error: undefined
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocationFailure, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.removeAll({
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loaded: true,
          error: action.error
        })
      }
    }
  })),

  on(LocationIpActions.updateLocationIpsForSelectedLocation, (state, action) => state),

  on(LocationIpActions.updateLocationIpsForSelectedLocationStart, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: true
        }
      }
    }
  })),

  on(LocationIpActions.updateLocationIpsForSelectedLocationSuccess, (state: State, action) => {
    // set new entities (clears collection)
    return {
      ...state,
      locationIp: {
        ..._.get(state, ['locationIp'], {}),
        [action.location_uuid]: {
          ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
          list: listAdapter.setAll(_.toArray(action.result), {
            ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
            meta: action.meta,
            loading: false,
            loaded: true,
            error: undefined
          })
        }
      }
    };
  }),

  on(LocationIpActions.updateLocationIpsForSelectedLocationFailure, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.removeAll({
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loaded: true,
          error: action.error
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocationNext, (state, action) => state),

  on(LocationIpActions.loadLocationIpsForSelectedLocationNextStart, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: _.get(state, ['locationIp', action.location_uuid, 'list', 'links', 'next']) !== undefined ? true : false,
          loadingNext: _.get(state, ['locationIp', action.location_uuid, 'list', 'links', 'next']) !== undefined ? true : false
        }
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocationNextSuccess, (state: State, action) => ({
    // upsert entities when next page loaded...
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: listAdapter.upsertMany(_.toArray(action.result), {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          meta: action.meta,
          links: action.links,
          loading: false,
          loadingNext: false,
          loaded: true
        })
      }
    }
  })),

  on(LocationIpActions.loadLocationIpsForSelectedLocationNextFailure, (state, action) => ({
    ...state,
    locationIp: {
      ..._.get(state, ['locationIp'], {}),
      [action.location_uuid]: {
        ..._.get(state, ['locationIp', action.location_uuid], adapterInitialState),
        list: {
          ..._.get(state, ['locationIp', action.location_uuid, 'list'], listAdapterInitialState),
          loading: false,
          loadingNext: false,
          loaded: true,
          error: action.error
        }
      }
    }
  }))

  /******* CUSTOM REDUCERS FROM HERE ******/
);
