import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLocationIp from './ip.reducer';
import { selectRouteNestedParam } from './../../../store/router/router.selectors';

export const selectLocationIpState = createFeatureSelector<fromLocationIp.State>(fromLocationIp.locationIpFeatureKey);
import * as _ from 'lodash';

import { adapterInitialState } from './ip.reducer';

const listSelectors = fromLocationIp.listAdapter.getSelectors((state: any) => state.list);

/**
 * Get total amount of LocationIps loaded into the store
 */
export const getLocationIpsTotalInStore = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  listSelectors.selectTotal(_.get(state, ['locationIp', props.location_uuid], adapterInitialState))
);

/**
 * Get list config with current total in store
 */
export const getLocationIpListConfigWithCurrentTotal = (props: { location_uuid: string }) => createSelector(
  selectLocationIpState,
  getLocationIpsTotalInStore(props),
  (state: fromLocationIp.State, currentTotal) => ({
    ..._.get(state, ['listConfig']),
    page: 0,
    // we calculate a limit which is at least the number of entities in the store, uprounded to a full new page regarding to the limit
    limit:
      currentTotal !== 0 ? Math.ceil(currentTotal / _.get(state, ['listConfig', 'limit'], 10)) * _.get(state, ['listConfig', 'limit'], 10) : _.get(state, ['listConfig', 'limit'])
  }));

/**
 * Get list config (sorting etc.)
 */
export const getLocationIpListConfig = createSelector(selectLocationIpState, (state: fromLocationIp.State) => _.get(state, ['listConfig'], {}));

/**
 * Get LocationIp list
 */
export const getLocationIps = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  listSelectors.selectAll(_.get(state, ['locationIp', props.location_uuid], adapterInitialState))
);

/**
 * Get LocationIp list meta data (total etc.)
 */
export const getLocationIpsMeta = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  _.get(state, ['locationIp', props.location_uuid, 'list', 'meta'])
);

/**
 * Get LocationIp list links, to load next bunch of data
 */
export const getLocationIpsLinks = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  _.get(state, ['locationIp', props.location_uuid, 'list', 'links'])
);

/**
 * Get if LocationIp list is loading
 */
export const isLocationIpListLoading = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  _.get(state, ['locationIp', props.location_uuid, 'list', 'loading'], false)
);

/**
 * Get if LocationIp list is loading next bunch of data
 */
export const isLocationIpListLoadingNext = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  _.get(state, ['locationIp', props.location_uuid, 'list', 'loadingNext'], false)
);

/**
 * get if LocationIp list is loaded
 */
export const isLocationIpListLoaded = (props: { location_uuid: string }) => createSelector(selectLocationIpState, (state: fromLocationIp.State) =>
  _.get(state, ['locationIp', props.location_uuid, 'list', 'loaded'], false)
);

export const getLocationIpsForSelectedLocation = createSelector(selectLocationIpState, selectRouteNestedParam('location_uuid'), (state: fromLocationIp.State, location_uuid) =>
  listSelectors.selectAll(_.get(state, ['locationIp', location_uuid], adapterInitialState))
);

/**
 * Get LocationIpsForSelectedLocation list meta data (total etc.)
 */
export const getLocationIpsForSelectedLocationMeta = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => _.get(state, ['locationIp', location_uuid, 'list', 'meta'], { total: 0, count: 0, limit: 0, offset: 0, page: 0 })
);

/**
 * Get LocationIpsForSelectedLocation list links, to load next bunch of data
 */
export const getLocationIpsForSelectedLocationLinks = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => _.get(state, ['locationIp', location_uuid, 'list', 'links'])
);

/**
 * Get total amount of LocationIpsForSelectedLocation loaded into the store
 */
export const getLocationIpsForSelectedLocationTotalInStore = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => listSelectors.selectTotal(_.get(state, ['locationIp', location_uuid]))
);

/**
 * Get if LocationIpsForSelectedLocation list is loading
 */
export const isLocationIpsForSelectedLocationListLoading = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => _.get(state, ['locationIp', location_uuid, 'list', 'loading'], false)
);

/**
 * Get if LocationIpsForSelectedLocation list is loading next bunch of data
 */
export const isLocationIpsForSelectedLocationListLoadingNext = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => _.get(state, ['locationIp', location_uuid, 'list', 'loadingNext'], false)
);

/**
 * get if LocationIpsForSelectedLocation list is loaded
 */
export const isLocationIpsForSelectedLocationListLoaded = createSelector(
  selectLocationIpState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocationIp.State, location_uuid) => _.get(state, ['locationIp', location_uuid, 'list', 'loaded'], false)
);
