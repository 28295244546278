import { createAction, props } from '@ngrx/store';

import { RequestPollResult, RequestOptions, Meta, Links, IpBriefIndex, IpsGetResponse } from '@gridscale/gsclient-js';

export const actionPrefix = '[location-ip]';

/********************************
 *          LIST ACTIONS        *
 ********************************/

export const setListConfig = createAction(actionPrefix + ' Set LocationIp List Config', props<{ config: RequestOptions }>());

export const loadLocationIps = createAction(actionPrefix + ' Load LocationIp List', props<{ location_uuid: string }>());

export const loadLocationIpsSuccess = createAction(
  actionPrefix + ' Load LocationIp List Success',
  props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta; links?: Links<IpsGetResponse> }>()
);

export const loadLocationIpsFailure = createAction(actionPrefix + ' Load LocationIp List Failure', props<{ location_uuid: string; error: Error }>());

export const updateLocationIps = createAction(actionPrefix + ' Update LocationIp List', props<{ location_uuid: string }>());

export const updateLocationIpsSuccess = createAction(actionPrefix + ' Update LocationIp List Success', props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta }>());

export const updateLocationIpsFailure = createAction(actionPrefix + ' Update LocationIp List Failure', props<{ location_uuid: string; error: Error }>());

export const loadLocationIpsNext = createAction(actionPrefix + ' Load LocationIp List Next', props<{ location_uuid: string }>());

export const loadLocationIpsNextSuccess = createAction(
  actionPrefix + ' Load LocationIp List Next Success',
  props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta; links?: Links<IpsGetResponse> }>()
);

export const loadLocationIpsNextFailure = createAction(actionPrefix + ' Load LocationIp List Next Failed', props<{ location_uuid: string; error: Error }>());

export const loadLocationIpsForSelectedLocation = createAction(actionPrefix + ' Load LocationIpForSelectedLocation List');

export const loadLocationIpsForSelectedLocationStart = createAction(actionPrefix + ' Load LocationIpForSelectedLocation List Start', props<{ location_uuid: string }>());

export const loadLocationIpsForSelectedLocationSuccess = createAction(
  actionPrefix + ' Load LocationIpForSelectedLocation List Success',
  props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta; links?: Links<IpsGetResponse> }>()
);

export const loadLocationIpsForSelectedLocationFailure = createAction(
  actionPrefix + ' Load LocationIpForSelectedLocation List Failure',
  props<{ location_uuid: string; error: Error }>()
);

export const updateLocationIpsForSelectedLocation = createAction(actionPrefix + ' Update LocationIpForSelectedLocation List');

export const updateLocationIpsForSelectedLocationStart = createAction(actionPrefix + ' Update LocationIpForSelectedLocation List Start', props<{ location_uuid: string }>());

export const updateLocationIpsForSelectedLocationSuccess = createAction(
  actionPrefix + ' Update LocationIpForSelectedLocation List Success',
  props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta }>()
);

export const updateLocationIpsForSelectedLocationFailure = createAction(
  actionPrefix + ' Update LocationIpForSelectedLocation List Failure',
  props<{ location_uuid: string; error: Error }>()
);

export const loadLocationIpsForSelectedLocationNext = createAction(actionPrefix + ' Load LocationIpForSelectedLocation List Next');

export const loadLocationIpsForSelectedLocationNextStart = createAction(actionPrefix + ' Load LocationIpForSelectedLocation List Next Start', props<{ location_uuid: string }>());

export const loadLocationIpsForSelectedLocationNextSuccess = createAction(
  actionPrefix + ' Load LocationIpForSelectedLocation List Next Success',
  props<{ location_uuid: string; result: IpBriefIndex; meta?: Meta; links?: Links<IpsGetResponse> }>()
);

export const loadLocationIpsForSelectedLocationNextFailure = createAction(
  actionPrefix + ' Load LocationIpForSelectedLocation List Next Failed',
  props<{ location_uuid: string; error: Error }>()
);

/********************************
 *      INTERNAL ACTIONS        *
 ********************************/

/******* CUSTOM ACTIONS FROM HERE ******/
