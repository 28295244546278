import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ButtonModule } from '../../elements/button';
import { SharedModule } from '../../helper/shared.module';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ig-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, SharedModule, NgIf]
})
export class PageNotFoundComponent {
  @Input() lastUrl?: string;

  readonly #router = inject(Router);

  goTo(_url: string) {
    this.#router.navigate([_url]);
  }

}
