import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { MetaReducer, ActionReducer, provideStore, provideState, Action } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as fromSession from './session/session.reducer';
import * as fromPrices from './prices/prices.reducer';
import * as fromPricesPerLocation from './prices_per_location/prices-per-location.reducer';
import { SessionEffects } from './session/session.effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { PricesEffects } from './prices/prices.effects';
import { PricesPerLocationEffects } from './prices_per_location/prices-per-location.effects';
import { logoutSuccess } from './session/session.actions';
import { environment } from './../../../environments/environment';
import * as fromLocation from './location/location.reducer';
import { LocationEffects } from './location/location.effects';
import * as fromUserStore from './userstore/userstore.reducer';
import { UserStoreEffects } from './userstore/userstore.effects';
import * as fromLocationIp from './location/ip/ip.reducer';
import { LocationIpEffects } from './location/ip/ip.effects';
import { UserEffects } from './user/user.effects';
import * as fromUser from './user/user.reducer';
import { ContractEffects } from './contract/contract.effects';
import * as fromContract from './contract/contract.reducer';
import { ProjectEffects } from './project/project.effects';
import * as fromProject from './project/project.reducer';
import { LimitsEffects } from './limits/limits.effects';
import * as fromLimits from './limits/limits.reducer';
import { routerCustomReducer } from './router/router.reducer';
import * as fromLabel from './label/label.reducer';
import { LabelEffects } from './label/label.effects';
import * as fromInvoice from './invoice/invoice.reducer';
import { InvoiceEffects } from './invoice/invoice.effects';
import * as fromConfig from './config/config.reducer';
import { BillingEffects } from './billing/billing.effects';
import * as fromBilling from './billing/billing.reducer';
import { CustomMenuEffects } from './custom-menu/custom-menu.effects';
import * as fromCustomMenu from './custom-menu/custom-menu.reducer';
import { SupportTicketEffects } from './support-ticket/support-ticket.effects';
import * as fromSupportTicket from './support-ticket/support-ticket.reducer';
import { NotificationEffects } from './notifications/notifications.effects';
import * as fromNotification from './notifications/notifications.reducer';
import { ServiceMarketplaceEffects } from './service_marketplace/service_marketplace.effects';
import * as fromServiceMarketplace from './service_marketplace/service_marketplace.reducer';


function reset(reducer: any) {
  /* resets the store on logout */
  return function newReducer(state: any, action: Action<any>) {
    if (action.type === logoutSuccess.type) {
      state = undefined;
    }

    const nextState = reducer(state, action);
    return nextState;
  };
}

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [reset, stateSetter];

@NgModule({
  providers: [
    provideStore({ router: routerReducer, routerCustom: routerCustomReducer }, { metaReducers }),
    provideRouterStore(),
    provideEffects([
      BillingEffects,
      ContractEffects,
      CustomMenuEffects,
      InvoiceEffects,
      LabelEffects,
      LimitsEffects,
      LocationEffects,
      LocationIpEffects,
      NotificationEffects,
      PricesEffects,
      PricesPerLocationEffects,
      ProjectEffects,
      ServiceMarketplaceEffects,
      SessionEffects,
      SupportTicketEffects,
      UserStoreEffects,
      UserEffects,
    ]),
    provideState(fromBilling.billingFeatureKey, fromBilling.reducer),
    provideState(fromConfig.configFeatureKey, fromConfig.configReducer),
    provideState(fromContract.featureKey, fromContract.reducer),
    provideState(fromCustomMenu.customMenuFeatureKey, fromCustomMenu.customMenuReducer),
    provideState(fromInvoice.invoiceFeatureKey, fromInvoice.reducer),
    provideState(fromLabel.labelFeatureKey, fromLabel.reducer),
    provideState(fromLimits.featureKey, fromLimits.reducer),
    provideState(fromLocation.locationFeatureKey, fromLocation.reducer),
    provideState(fromLocationIp.locationIpFeatureKey, fromLocationIp.reducer),
    provideState(fromNotification.notificationFeatureKey, fromNotification.notificationReducer),
    provideState(fromPrices.featureKey, fromPrices.reducer),
    provideState(fromPricesPerLocation.featureKey, fromPricesPerLocation.reducer),
    provideState(fromProject.featureKey, fromProject.reducer),
    provideState(fromServiceMarketplace.featureKey, fromServiceMarketplace.reducer),
    provideState(fromSession.sessionFeatureKey, fromSession.reducer),
    provideState(fromSupportTicket.support_ticket_FeatureKey, fromSupportTicket.supportTicketReducer),
    provideState(fromUser.featureKey, fromUser.reducer),
    provideState(fromUserStore.featureKey, fromUserStore.reducer),
    provideStoreDevtools({
      maxAge: 150,
      logOnly: environment.production,
    }),
  ]
})
export class RootStoreModule { }
