import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MiddlewareService } from './../../services/middleware.service';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { globalFailure } from '../common.actions';

import * as UserStoreActions from './userstore.actions';
import { initedUserStoreEffects } from './userstore.actions';

@Injectable()
export class UserStoreEffects implements OnInitEffects {
  ngrxOnInitEffects(): Action {
    return initedUserStoreEffects();
  }

  loadUserStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.loadUserStore, UserStoreActions.saveUserStoreSuccess),
      concatMap(action => {
        const params = new HttpParams();

        return this.middleware.get('/v2/store/user', params).pipe(
          map((result: any) =>
            UserStoreActions.loadUserStoreSuccess({
              data: result && result.body ? result.body : {}
            })
          ),
          catchError(error => of(UserStoreActions.loadUserStoreFailure({ error }), globalFailure({ error })))
        );
      })
    )
  );

  saveUserStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.saveUserStore),
      concatMap(action => {
        return this.middleware.post('/v2/store/user', action.data).pipe(
          map((result: any) =>
            UserStoreActions.saveUserStoreSuccess({
              data: action.data
            })
          ),
          catchError(error => of(UserStoreActions.saveUserStoreFailure({ error }), globalFailure({ error })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private middleware: MiddlewareService, private readonly store: Store) { }
}
