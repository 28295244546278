<div class="page-not-found__container">
  <div class="page-not-found">

      <div class="page-not-found__inner">
        <div class="page-not-found__graphic">
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 260 260" style="enable-background:new 0 0 260 260;" xml:space="preserve">
          <path class="st0" d="M227.14,181.26c-0.85,1.73-27.8,54.64-91.34,57.87c-60.83,3.38-93.46-44.27-93.37-44.42
            c3.88-6.4,9.34-11.25,15.38-15.09c0.45,0.35,0.96,0.91,3.84,0.97c4.32-1.2,4.18-4.24,4.25-4.9c4.92-1.71,9.91-2.78,15.22-2.91
            c5.13-0.12,10.77,0.7,15.39,2.22c0.62-2,1.17-3.27,2.05-5.14c0.24,0.2,3.61,2.57,4.86,3.33c0.49,0.3,13.62,9.59,32.29,7.29
            c27.28-4.06,36.96-24.41,37.39-25.39c5.79,5.33,10.24,12.15,12.65,19.54c4.66-1.56,10.31-2.41,15.5-2.28
            C213.12,172.67,219.91,175.75,227.14,181.26z M136.82,169.7c19.7-4.77,25.6-20.22,25.81-20.59c-6.5-3.44-13.93-5.4-21.81-5.4
            c-14.38,0-27.23,6.49-35.72,16.67C104.94,160.56,118.04,173.69,136.82,169.7z"/>
          <path class="st1" d="M57.96,179.75c0.26,0.23,0.57,0.44,0.9,0.58c-6.17,3.65-11.49,8.7-15.4,14.84c-0.23-0.33-0.52-0.63-0.84-0.82
            c3.9-6.06,9.14-11.08,15.21-14.75C57.88,179.65,57.91,179.71,57.96,179.75z M66.27,111.27c3.57-1.35,7.41-2.09,11.41-2.09
            c2.7,0,5.33,0.35,7.86,0.99c0.13-0.37,0.29-0.72,0.46-1.07c-2.67-0.69-5.45-1.06-8.31-1.06c-4.03,0-7.91,0.73-11.52,2.06
            C66.28,110.47,66.31,110.87,66.27,111.27z M96.5,175c-4.67-1.48-9.48-2.24-14.3-2.24c-5.56,0-10.97,1.01-16.03,2.85
            c-0.05,0.37-0.1,0.74-0.19,1.09c-0.02,0.08-0.05,0.15-0.07,0.22c5.13-1.96,10.64-3.03,16.3-3.03c4.89,0,9.76,0.79,14.5,2.36
            l0.55,0.18l0.17-0.56c0.6-1.94,1.34-3.82,2.17-5.62c-0.34-0.2-0.65-0.43-0.95-0.68C97.82,171.34,97.1,173.14,96.5,175z M46.7,132.31
            l0.18-0.56c1.74-5.52,4.86-10.28,8.89-14c-0.18-0.43-0.25-0.9-0.21-1.36c-4.31,3.8-7.67,8.73-9.59,14.47
            c-5.21-1.66-10.68-1.98-15.91-1.07c-0.27,0.47-0.59,0.92-0.94,1.34c5.57-1.17,11.47-0.89,17.04,0.99L46.7,132.31z M229.74,131.53
            c-4.49,0-8.97,0.71-13.34,2.13c-1.07-3.24-2.49-6.27-4.21-9.08c-0.2,0.27-0.44,0.51-0.73,0.68c-0.05,0.02-0.1,0.04-0.15,0.06
            c1.73,2.85,3.14,5.94,4.18,9.23l0.18,0.56l0.55-0.19c4.42-1.49,8.97-2.25,13.52-2.25c1.16,0,2.21,0.04,3.23,0.1
            c-0.04-0.39,0-0.78,0.12-1.14C232.04,131.57,230.95,131.53,229.74,131.53z M200.17,172.35c-4.86,0-9.72,0.77-14.45,2.29
            c-2.55-7.68-6.97-14.33-12.65-19.54c-0.19,0.34-0.39,0.67-0.62,0.98c5.6,5.19,9.92,11.81,12.36,19.46l0.18,0.55l0.55-0.18
            c4.78-1.6,9.7-2.41,14.63-2.41c10.77,0,19.17,2.81,26.46,8.83c0,0,0.28,0.35,0.58-0.08c0.3-0.43,0.17-0.82-0.07-0.99
            C219.69,175.18,211.11,172.35,200.17,172.35z M205.24,117.51c0.17-0.35,0.42-0.65,0.74-0.87c-7.95-8.01-18.92-12.78-30.82-12.78
            c-1.47,0-2.92,0.08-4.35,0.23c0.18,0.36,0.34,0.73,0.48,1.1c1.27-0.12,2.56-0.19,3.87-0.19C186.78,105,197.49,109.67,205.24,117.51z
            "/>
          <path class="st2" d="M75.82,147.98h-9.71V87.95c0-3.16-2.47-5.63-5.77-5.63c-2.89,0-4.4,1.24-6.05,3.3l-50,61
            c-1.37,1.79-2.06,3.3-2.06,5.22c0,3.02,2.34,5.22,5.5,5.22h47.81v18.27c0,2.88,2.33,5.22,5.22,5.22c3.02,0,5.36-2.34,5.36-5.22
            v-18.27h9.71c2.47,0,4.67-1.92,4.67-4.4C80.49,150.04,78.3,147.98,75.82,147.98z M55.54,147.98H15.15l40.39-49.59V147.98z
            M253.09,147.98h-9.71V87.95c0-3.16-2.47-5.63-5.77-5.63c-2.89,0-4.4,1.24-6.05,3.3l-50,61c-1.37,1.79-2.06,3.3-2.06,5.22
            c0,3.02,2.34,5.22,5.5,5.22h47.81v18.27c0,2.88,2.34,5.22,5.22,5.22c3.02,0,5.36-2.34,5.36-5.22v-18.27h9.71
            c2.47,0,4.67-1.92,4.67-4.4C257.76,150.04,255.56,147.98,253.09,147.98z M232.81,147.98h-40.39l40.39-49.59V147.98z M129.74,82.04
            c-27.27,0-49.38,22.11-49.38,49.38c0,27.27,22.11,49.38,49.38,49.38c27.27,0,49.38-22.11,49.38-49.38
            C179.13,104.15,157.02,82.04,129.74,82.04z M129.74,170.47c-20.49,0-37.09-17.48-37.09-39.04c0-21.56,16.61-39.04,37.09-39.04
            c20.49,0,37.09,17.48,37.09,39.04C166.84,152.99,150.23,170.47,129.74,170.47z"/>
          <path class="st3" d="M227.32,182.43C208.03,217.94,170.97,240,130.6,240c-47.14,0-89-29.97-104.17-74.57
            c-0.13-0.39,0.08-0.81,0.47-0.94c0.39-0.13,0.81,0.08,0.95,0.47c14.96,44,56.26,73.56,102.76,73.56c39.82,0,76.39-21.76,95.41-56.79
            c0.2-0.36,0.65-0.5,1.01-0.3S227.52,182.07,227.32,182.43z M22.19,116.32c0.03,0,0.07,0.01,0.1,0.01c0.37,0,0.69-0.27,0.74-0.65
            c7.08-53.7,53.32-94.19,107.57-94.19c39.82,0,76.39,21.76,95.41,56.79c0.2,0.36,0.65,0.49,1.01,0.3c0.36-0.2,0.5-0.65,0.3-1.01
            C208.03,42.06,170.97,20,130.6,20C75.6,20,28.72,61.05,21.55,115.49C21.49,115.9,21.78,116.27,22.19,116.32z M144.47,125.2
            c1.67,0,3.03-1.36,3.03-3.03c0-1.67-1.36-3.03-3.03-3.03c-1.67,0-3.03,1.36-3.03,3.03C141.44,123.84,142.8,125.2,144.47,125.2z
            M129.61,135.58c-6.48,0-12.7,2.39-17.51,6.73c-0.36,0.33-0.39,0.9-0.06,1.26c0.33,0.36,0.89,0.39,1.26,0.06
            c4.48-4.04,10.27-6.27,16.31-6.27c6.22,0,12.13,2.34,16.66,6.6c0.17,0.16,0.38,0.24,0.61,0.24c0.24,0,0.48-0.1,0.65-0.28
            c0.33-0.36,0.32-0.92-0.04-1.26C142.63,138.1,136.28,135.58,129.61,135.58z M114.38,125.2c1.67,0,3.03-1.36,3.03-3.03
            c0-1.67-1.36-3.03-3.03-3.03c-1.67,0-3.03,1.36-3.03,3.03C111.35,123.84,112.71,125.2,114.38,125.2z M258.51,152.65
            c0,2.83-2.43,5.14-5.42,5.14h-8.96v17.53c0,3.35-2.68,5.97-6.1,5.97c-3.29,0-5.97-2.68-5.97-5.97v-17.53H185
            c-3.56,0-6.24-2.56-6.24-5.96c0-1.99,0.64-3.63,2.22-5.67l50.02-61.01c1.76-2.2,3.49-3.57,6.62-3.57c3.65,0,6.51,2.8,6.51,6.38
            v59.29h8.96C256.03,147.23,258.51,149.71,258.51,152.65z M257.02,152.65c0-2.13-1.8-3.93-3.93-3.93h-10.45V87.95
            c0-2.79-2.16-4.89-5.02-4.89c-2.56,0-3.9,1.06-5.46,3.02l-50.01,61c-1.36,1.76-1.89,3.1-1.89,4.75c0,2.59,2,4.47,4.75,4.47h48.55
            v19.02c0,2.47,2.01,4.48,4.48,4.48c2.59,0,4.61-1.97,4.61-4.48V156.3h10.45C255.22,156.3,257.02,154.63,257.02,152.65z
            M190.85,148.72l42.7-52.43v52.43H190.85z M232.06,100.48l-38.08,46.75h38.08V100.48z M54.79,157.79H7.73
            c-3.56,0-6.24-2.56-6.24-5.96c0-1.98,0.64-3.63,2.22-5.67l50.02-61.01c1.76-2.2,3.49-3.57,6.62-3.57c3.65,0,6.51,2.8,6.51,6.38
            v59.29h8.96c2.94,0,5.42,2.48,5.42,5.42c0,2.83-2.43,5.14-5.42,5.14h-8.96v17.53c0,3.35-2.68,5.97-6.1,5.97
            c-3.29,0-5.97-2.68-5.97-5.97V157.79z M56.28,156.3v19.02c0,2.47,2.01,4.48,4.47,4.48c2.59,0,4.61-1.97,4.61-4.48V156.3h10.45
            c2.13,0,3.93-1.67,3.93-3.65c0-2.13-1.8-3.93-3.93-3.93H65.37V87.95c0-2.79-2.16-4.89-5.02-4.89c-2.56,0-3.9,1.06-5.46,3.02
            l-50.01,61c-1.36,1.76-1.89,3.1-1.89,4.75c0,2.59,2,4.47,4.75,4.47H56.28z M129.74,81.3c27.64,0,50.13,22.49,50.13,50.13
            s-22.49,50.13-50.13,50.13s-50.13-22.49-50.13-50.13S102.1,81.3,129.74,81.3z M129.74,82.79c-26.82,0-48.64,21.82-48.64,48.64
            s21.82,48.64,48.64,48.64s48.64-21.82,48.64-48.64S156.56,82.79,129.74,82.79z M56.28,148.72h-42.7l42.7-52.43V148.72z
            M54.79,100.48l-38.08,46.75h38.08V100.48z M129.74,91.64c20.86,0,37.84,17.85,37.84,39.79s-16.97,39.79-37.84,39.79
            s-37.84-17.85-37.84-39.79S108.88,91.64,129.74,91.64z M129.74,93.13c-20.04,0-36.35,17.18-36.35,38.3s16.31,38.3,36.35,38.3
            s36.35-17.18,36.35-38.3S149.79,93.13,129.74,93.13z"/>
          </svg>
        </div>
        <h1 class="page-not-found__headline">{{ 'GENERAL.PAGE_NOT_FOUND' | uitranslate }}</h1>
        <p class="p-mb-5">{{ 'GENERAL.PAGE_NOT_FOUND_TEXT' | uitranslate }}</p>
        <ig-button mode="primary" *ngIf="lastUrl" (onClick)="goTo(lastUrl!)" [label]="'GENERAL.GO_BACK' | uitranslate"></ig-button>
        <ig-button mode="primary" *ngIf="!lastUrl" (onClick)="goTo('/')" [label]="'GENERAL.GO_BACK' | uitranslate"></ig-button>
      </div>

  </div>
</div>

