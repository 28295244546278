import { STEP, UserStateService } from './../services/userstate.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupIncompleteGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userStateService: UserStateService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.userStateService.hasDoneStep$(STEP.READY), this.userStateService.getNextStep$()]).pipe(
      map(([done, nextStep]) => {
        // If partner has no signup, redirect to dashboard
        if (done || nextStep === STEP.COMPLETE_PAYMENT || nextStep === STEP.READY || nextStep === STEP.WAITING_FOR_SEPA || nextStep === STEP.AWAITING_ACTIVATION) {
          this.router.navigate(['/Dashboard']);
          return false;
        }

        return true;
      })
    );
  }
}
