import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, withLatestFrom, concatMap, mergeMap, tap, filter, delay, switchMap } from 'rxjs/operators';
import { Observable, of, zip, forkJoin } from 'rxjs';

import * as LimitsActions from './../../../store/limits/limits.actions';
import * as LocationIpActions from './ip.actions';

import * as LocationActions from './../../../store/location/location.actions';

import * as _ from 'lodash';

import { ApiService, apiClient } from './../../../services/api.service';
import { globalFailure, noopAction } from './../../../store/common.actions';

import { Store } from '@ngrx/store';
import { getLocationIpListConfig, getLocationIpListConfigWithCurrentTotal, getLocationIpsLinks, getLocationIpsForSelectedLocationLinks } from './ip.selectors';

import { selectRouteNestedParam } from './../../../store/router/router.selectors';

@Injectable()
export class LocationIpEffects {
  loadLocationIps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.loadLocationIps),
      withLatestFrom(this.store.select(getLocationIpListConfig)),
      concatMap(([action, config]) =>
        this.api.$<ReturnType<typeof apiClient.Location.getLocationIPs>>('Location', 'getLocationIPs', action.location_uuid, config).pipe(
          map(result =>
            LocationIpActions.loadLocationIpsSuccess({
              location_uuid: action.location_uuid,
              result: result.result.ips || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationIpActions.loadLocationIpsFailure({ error, location_uuid: action.location_uuid }), globalFailure({ error })))
        )
      )
    )
  );

  updateLocationIps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.updateLocationIps),
      mergeMap(_action => of(_action).pipe(withLatestFrom(this.store.select(getLocationIpListConfigWithCurrentTotal({ location_uuid: _action.location_uuid }))))),
      concatMap(([action, config]) => {
        return this.api.$<ReturnType<typeof apiClient.Location.getLocationIPs>>('Location', 'getLocationIPs', action.location_uuid, config).pipe(
          map(result =>
            LocationIpActions.updateLocationIpsSuccess({
              location_uuid: action.location_uuid,
              result: result.result.ips || {},
              meta: result.meta
            })
          ),
          catchError(error => of(LocationIpActions.updateLocationIpsFailure({ error, location_uuid: action.location_uuid }), globalFailure({ error })))
        );
      })
    )
  );

  loadLocationIpsNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.loadLocationIpsNext),

      mergeMap(action => of(action).pipe(withLatestFrom(this.store.select(getLocationIpsLinks({ location_uuid: action.location_uuid }))))),

      filter(([_, links]) => links !== undefined && links.next !== undefined),
      concatMap(([action, links]) =>
        this.api.fromFunction$(links!.next!, links).pipe(
          map(result =>
            LocationIpActions.loadLocationIpsNextSuccess({
              location_uuid: action.location_uuid,
              result: result.result.ips || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationIpActions.loadLocationIpsNextFailure({ error, location_uuid: action.location_uuid }), globalFailure({ error })))
        )
      )
    )
  );

  watchRunning = false;

  watchProvisioningList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LocationIpActions.loadLocationIpsSuccess,
        LocationIpActions.loadLocationIpsNextSuccess,
      ),
      filter(action => this.watchRunning === false && typeof action.result === 'object' && action.result !== null),
      delay(100),
      switchMap(action => {
        const observables: Observable<boolean>[] = [];
        _.forEach(
          _.filter(action.result, obj => obj.status !== 'active'),
          provisioningObj => {
            observables.push(of(false));
          }
        );

        return forkJoin([
          of(action),
          new Observable(_observer => {
            if (observables.length === 0) {
              // _observer.next((watchings).indexOf(true) >= 0);
              _observer.next(true);
              _observer.complete();
              return;
            }
            this.watchRunning = true;
            zip(...observables).subscribe(watchings => {
              _observer.next(watchings.indexOf(true) >= 0);
              _observer.complete();
            });
          }) as Observable<boolean>
        ]);
      }),
      delay(4000),
      tap(() => this.watchRunning = false),
      map(([action, oneWatching]) => (oneWatching ? noopAction() : LocationIpActions.loadLocationIps({ location_uuid: action.location_uuid })))
    )
  );

  loadLocationIpsForSelectedLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.loadLocationIpsForSelectedLocation),
      withLatestFrom(this.store.select(getLocationIpListConfig), this.store.select(selectRouteNestedParam('location_uuid'))),
      filter(([action, config, location_uuid]) => location_uuid !== undefined),
      tap(([action, config, location_uuid]) => this.store.dispatch(LocationIpActions.loadLocationIpsForSelectedLocationStart({ location_uuid }))),
      concatMap(([action, config, location_uuid]) =>
        this.api.$<ReturnType<typeof apiClient.Location.getLocationIPs>>('Location', 'getLocationIPs', location_uuid, config).pipe(
          map(result =>
            LocationIpActions.loadLocationIpsForSelectedLocationSuccess({
              location_uuid,
              result: result.result.ips || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationIpActions.loadLocationIpsForSelectedLocationFailure({ error, location_uuid }), globalFailure({ error })))
        )
      )
    )
  );

  updateLocationIpsForSelectedLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.updateLocationIpsForSelectedLocation),
      withLatestFrom(this.store.select(selectRouteNestedParam('location_uuid'))),
      filter(([action, location_uuid]) => location_uuid !== undefined),
      mergeMap(([action, location_uuid]) =>
        of(action).pipe(withLatestFrom(this.store.select(getLocationIpListConfigWithCurrentTotal({ location_uuid })), this.store.select(selectRouteNestedParam('location_uuid'))))
      ),
      tap(([action, config, location_uuid]) => this.store.dispatch(LocationIpActions.updateLocationIpsForSelectedLocationStart({ location_uuid }))),
      concatMap(([action, config, location_uuid]) =>
        this.api.$<ReturnType<typeof apiClient.Location.getLocationIPs>>('Location', 'getLocationIPs', location_uuid, config).pipe(
          map(result =>
            LocationIpActions.updateLocationIpsForSelectedLocationSuccess({
              location_uuid,
              result: result.result.ips || {},
              meta: result.meta
            })
          ),
          catchError(error => of(LocationIpActions.updateLocationIpsForSelectedLocationFailure({ error, location_uuid }), globalFailure({ error })))
        )
      )
    )
  );

  loadLocationIpsForSelectedLocationNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationIpActions.loadLocationIpsForSelectedLocationNext),
      withLatestFrom(this.store.select(selectRouteNestedParam('location_uuid'))),
      filter(([action, location_uuid]) => location_uuid !== undefined),
      mergeMap(([action, location_uuid]) => of(action).pipe(withLatestFrom(this.store.select(getLocationIpsForSelectedLocationLinks), of(location_uuid)))),
      filter(([_, links]) => links !== undefined && links.next !== undefined),
      tap(([action, config, location_uuid]) => this.store.dispatch(LocationIpActions.loadLocationIpsForSelectedLocationNextStart({ location_uuid }))),
      concatMap(([action, links, location_uuid]) =>
        this.api.fromFunction$(links!.next!, links).pipe(
          map(result =>
            LocationIpActions.loadLocationIpsForSelectedLocationNextSuccess({
              location_uuid,
              result: result.result.ips || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationIpActions.loadLocationIpsForSelectedLocationNextFailure({ error, location_uuid }), globalFailure({ error })))
        )
      )
    )
  );

  /**** CUSTOM EFFECTS FROM HERE ****/

  constructor(private actions$: Actions, private api: ApiService, private readonly store: Store) { }
}
