import { concatMap, catchError, map, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NotificationActions from './notifications.actions';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { getCurrentContractUuid } from '../session/session.selectors';
import { Store } from '@ngrx/store';
import { MaintenanceService } from '@gridscale/gs-services';

@Injectable()
export class NotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient, private store: Store,
    private readonly maintenanceService: MaintenanceService
  ) { }

  getAccountNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getAccountNotifications),
      withLatestFrom(this.store.select(getCurrentContractUuid)),
      tap(([action, uuid]) => {
        this.maintenanceService.setup(uuid);
      }),
      concatMap(() => this.maintenanceService.maintenances$),
      map(maintenances => NotificationActions.getAccountNotificationsSuccess({ data: maintenances })),
      catchError(error => of(NotificationActions.getAccountNotificationsFailure({ error })))
    )
  );
}
