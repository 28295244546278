import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import * as PricesPerLocationActions from './prices-per-location.actions';
import { concatMap, map, catchError, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { globalFailure } from '../common.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { Store } from '@ngrx/store';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { ErrorHandlerService } from './../../services/errorhandler.service';

@Injectable()
export class PricesPerLocationEffects {
  loadPricesPerLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricesPerLocationActions.loadPricesPerLocation),
      concatMap(action => {
        return this.middleware.get('/management/v2/contracts/current/prices').pipe(


          tap((result: any) => {
            if (parseInt(result.meta?.total, 10) > parseInt(result.meta?.limit, 10)) {
              // TODO: we should handle that somewhen
              this.errorHandler.log({
                displayToast: false,
                logToSentry: true,
                summary: 'We have to handle pagination',
                detail: 'More locations as the limit exists and we currently dont handle pagination for management API. Do it now!',
                sentryExtra: {
                  meta: JSON.stringify(result.meta)
                }
              })
            }
          }),
          map((result: any) =>
            PricesPerLocationActions.loadPricesPerLocationSuccess({
              rawResult: result,
              result: result.data ?
                {
                  locations: _.map(result.data, l => ({ ...l.attributes, location_uuid: l.id }))
                }
                : { locations: [] }
            })
          ),
          catchError(error => of(PricesPerLocationActions.loadPricesPerLocationFailure({ error }), globalFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private middleware: MiddlewareService,
    private readonly store: Store,
    private readonly config: ConfigService,
    private readonly errorHandler: ErrorHandlerService
  ) { }
}
