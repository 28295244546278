<router-outlet></router-outlet>
<p-toast key="global" position="top-center" [baseZIndex]="2001" [preventOpenDuplicates]="true"></p-toast>
<p-toast key="errorhandlerservice" position="top-center" styleClass="errorhandler-toast-container" [baseZIndex]="2001" [preventOpenDuplicates]="true">
  <ng-template let-message pTemplate="message">
    <div class="errorhandler-toast">
      <span *ngIf="message.severity !== 'error'" [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')" [ngClass]="{'pi-info-circle': message.severity === 'info', 'pi-exclamation-triangle': message.severity === 'warn',
                          'pi-times-circle': message.severity === 'error', 'pi-check' :message.severity === 'success'}"></span>
      <div class="p-toast-message-text">
          <div class="p-toast-summary">{{message.summary}}</div>
          <div class="p-toast-detail">{{message.detail}}</div>
          <div *ngIf="message.data?.incidents && message.data?.statusPage">
            <a class="sentry-link" [attr.href]="message.data?.statusPage" target="_blank">
              {{ 'ERRORS.WE_HAVE_INCIDENTS' | translate:{count: message.data?.incidents } }}&nbsp;
              <i class="icon-a106_linkextern"></i>
            </a>
          </div>
          <div *ngIf="message.data?.sentry_id && message.data?.sentryFeedback">
            <a class="sentry-link" (click)="message.data.sentryFeedback()">{{ 'ERRORS.SEND_SENTRY_FEEDBACK' | translate }}</a>
          </div>
          <div class="request-id" *ngIf="message.data?.request_id">
            Request ID: <ig-copy-clipboard>{{ message.data.request_id }}</ig-copy-clipboard>
          </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast key="advancedModeRevert" position="top-right" [baseZIndex]="2001" [preventOpenDuplicates]="true" #myToast>
  <ng-template let-message pTemplate="message">
    <div class="advanced-mode-toast">
      <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')" [ngClass]="{'pi-info-circle': true }"></span>
      <div class="p-toast-message-text">
          <div class="p-toast-summary">{{ 'DETAILS.ADVANCED_REVERT_HEADLINE' | translate }}</div>
          <div class="p-toast-detail">{{ 'DETAILS.ADVANCED_REVERT_MESSAGE' | translate }}</div>

          <a (click)="myToast.messageService.clear(); message.data.onRevert();">
            {{ 'DETAILS.ADVANCED_REVERT' | translate }}
            <i class="far fa-undo"></i>
          </a>

      </div>
    </div>
  </ng-template>
</p-toast>
<div data-e2e-test="app-stable" [class.stable]="appStable"></div>

<!-- <div style="position: fixed; top: 0; left: 0; min-width: 200px; min-height: 200px; white-space: pre-wrap; z-index: 9999; background: #000; color: lime;">{{ openRequests | json }}</div> -->
