import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, withLatestFrom, concatMap, mergeMap, tap, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import * as LocationActions from './location.actions';

import * as ContractActions from './../contract/contract.actions';

import * as _ from 'lodash';

import { ApiService, apiClient } from './../../services/api.service';
import { globalFailure } from './../../store/common.actions';

import { Store } from '@ngrx/store';
import { getLocationListConfig, getLocationListConfigWithCurrentTotal, getLocationsLinks } from './location.selectors';

import { selectRouteNestedParam } from './../../store/router/router.selectors';
import { getCurrentProjectLocationUuid } from '../project/project.selectors';

@Injectable()
export class LocationEffects {
  loadLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadLocations, ContractActions.loadCurrentContractSuccess),
      withLatestFrom(this.store.select(getLocationListConfig)),
      concatMap(([action, config]) =>
        this.api.$<ReturnType<typeof apiClient.Location.list>>('Location', 'list', config).pipe(
          map(result =>
            LocationActions.loadLocationsSuccess({
              result: result.result.locations || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationActions.loadLocationsFailure({ error }), globalFailure({ error })))
        )
      )
    )
  );

  updateLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.updateLocations),
      mergeMap(_action => of(_action).pipe(withLatestFrom(this.store.select(getLocationListConfigWithCurrentTotal, {})))),
      concatMap(([action, config]) => {
        return this.api.$<ReturnType<typeof apiClient.Location.list>>('Location', 'list', config).pipe(
          map(result =>
            LocationActions.updateLocationsSuccess({
              result: result.result.locations || {},
              meta: result.meta
            })
          ),
          catchError(error => of(LocationActions.updateLocationsFailure({ error }), globalFailure({ error })))
        );
      })
    )
  );

  loadLocationsNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadLocationsNext),

      withLatestFrom(this.store.select(getLocationsLinks)),

      filter(([_, links]) => links !== undefined && links.next !== undefined),
      concatMap(([action, links]) =>
        this.api.fromFunction$(links!.next!, links).pipe(
          map(result =>
            LocationActions.loadLocationsNextSuccess({
              result: result.result.locations || {},
              meta: result.meta,
              links: result.links
            })
          ),
          catchError(error => of(LocationActions.loadLocationsNextFailure({ error }), globalFailure({ error })))
        )
      )
    )
  );

  loadLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadLocation),
      concatMap(_action =>
        this.api.$<ReturnType<typeof apiClient.Location.get>>('Location', 'get', _action.uuid).pipe(
          map(result =>
            LocationActions.loadLocationSuccess({
              data: result.result.location || {},
              uuid: _action.uuid
            })
          ),
          catchError(error =>
            of(
              LocationActions.loadLocationFailure({
                error,
                uuid: _action.uuid
              }),
              globalFailure({ error, whitelist: [404] })
            )
          )
        )
      )
    )
  );

  loadSelectedLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadSelectedLocation),
      withLatestFrom(this.store.select(selectRouteNestedParam('location_uuid'))),
      filter(([_action, _location_uuid]) => _location_uuid !== undefined),
      tap(([_action, _location_uuid]) => this.store.dispatch(LocationActions.loadSelectedLocationStart({ uuid: _location_uuid }))),
      concatMap(([_action, _location_uuid]) =>
        this.api.$<ReturnType<typeof apiClient.Location.get>>('Location', 'get', _location_uuid).pipe(
          map(result =>
            LocationActions.loadSelectedLocationSuccess({
              data: result.result.location || {},
              uuid: _location_uuid
            })
          ),
          catchError(error =>
            of(
              LocationActions.loadSelectedLocationFailure({
                error,
                uuid: _location_uuid
              }),
              globalFailure({ error, whitelist: [404] })
            )
          )
        )
      )
    )
  );

  loadCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.loadCurrentLocation),
      withLatestFrom(this.store.select(getCurrentProjectLocationUuid)),
      filter(([action, currentLocationUuid]) => !!currentLocationUuid),
      map(([action, currentLocationUuid]) => LocationActions.loadLocation({ uuid: currentLocationUuid }))
    )
  );

  /**** CUSTOM EFFECTS FROM HERE ****/

  constructor(private actions$: Actions, private api: ApiService, private readonly store: Store) { }
}
