import * as fromRouter from '@ngrx/router-store';
import { routerNavigatedAction } from '@ngrx/router-store';
import { createReducer, on } from '@ngrx/store';
import _ from 'lodash';
import { clearDetailViewUrls, popDetailViewUrl, pushDetailViewUrl, setInitUrl } from './router.actions';

export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export interface CustomState {
  urlCurrent: string;
  /**
   * always the last url before navigating
   */
  urlLast: string;
  /**
   * a self constructed name of the last component we navigated into
   */
  fakeComponentLast: string;
  urlEntry: string;
  navigationCount: number;
  detailViewUrls: string[];
}

export const initialCustomState: CustomState = {
  urlCurrent: '',
  urlLast: '',
  fakeComponentLast: '',
  urlEntry: '',
  navigationCount: 0,
  detailViewUrls: [],
};

export const routerCustomReducer = createReducer(
  initialCustomState,

  on(pushDetailViewUrl, (state, action) => {
    const urls = _.clone(state.detailViewUrls);
    urls.push(action.url);

    return {
      ...state,
      detailViewUrls: urls
    };
  }),

  on(popDetailViewUrl, (state, action) => {
    const urls = _.clone(state.detailViewUrls);
    urls.pop()

    return {
      ...state,
      detailViewUrls: urls
    };
  }),

  on(clearDetailViewUrls, (state, action) => ({
    ...state,
    detailViewUrls: []
  })),


  on(routerNavigatedAction, (state, action) => {
    // is the component distinct from the last?
    // we cannot get the current component from the route, so we have to guess based on URL
    let componentFakeName = '';
    const newUrl = _.get(action, 'payload.event.urlAfterRedirects', action.payload?.event?.url);
    if (newUrl) {
      const parts = newUrl.split('/');

      parts.forEach(part => {
        if (componentFakeName !== '') {
          componentFakeName += '_';
        }

        if (part.match(/^([a-z0-9]+\-){3,}[a-z0-9]+$/)) {
          // it's an UUID, the component is the same
          componentFakeName += 'detail'
        } else {
          componentFakeName += part.toLowerCase()
        }
      });
    }

    return {
      ...state,
      fakeComponentLast: componentFakeName,
      urlLast: state.urlCurrent,
      urlCurrent: action.payload.event.url,
      navigationCount: state.navigationCount + 1
    };
  }),

  on(setInitUrl, (state, action) => ({
    ...state,
    urlCurrent: action.url,
    urlEntry: action.url
  }))
);
