import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MiddlewareService } from './../../services/middleware.service';
import { Store } from '@ngrx/store';
import * as ProjectActions from './project.actions';
import * as SessionActions from './../session/session.actions';
import * as SessionSelectors from './../session/session.selectors';
import * as ContractActions from './../contract/contract.actions';
import * as ContractSelectors from './../contract/contract.selectors';
import { map, catchError, concatMap, withLatestFrom, take, tap, switchMap, filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { globalFailure, noopAction, toastAction } from '../common.actions';
import { of } from 'rxjs';
import { OverlayAlertService } from '@gridscale/ingrid/components/overlay-alert';
import { TranslateService } from '@ngx-translate/core';
import { getProject, isProjectLoaded, isProjectLoading } from './project.selectors';
import { Project } from './project.reducer';
import { TrackingService } from '../../../auth/tracking.service';

@Injectable()
export class ProjectEffects {
  /***********
   *  Load Single Project
   **********/
  loadCurrentProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadCurrentProject, SessionActions.loadSessionSuccess),

      withLatestFrom(this.store.select(SessionSelectors.getCurrentProjectUuid)),
      concatMap(([_action, _project_uuid]) => {
        if (_project_uuid) {
          return of(ProjectActions.loadProject({ uuid: _project_uuid }));
        } else {
          return of(noopAction());
        }
      })
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProject, ProjectActions.createProjectSuccess, ProjectActions.patchProjectSuccess),
      concatMap(action => {
        const params = new HttpParams();
        return this.middleware.get('/v3/partners/contracts/current/projects/' + action.uuid, params).pipe(
          map((result: any) =>
            ProjectActions.loadProjectSuccess({
              data: result.body && result.body.project ? result.body.project : {},
              uuid: action.uuid
            })
          ),
          catchError(error => of(ProjectActions.loadProjectFailure({ error, uuid: action.uuid }), globalFailure({ error, whitelist: [404] })))
        );
      })
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProject),
      concatMap(action => {
        return this.middleware.post('/v3/partners/contracts/current/projects', action.data).pipe(
          map((result: any) => {
            if (result.code == 202) {
              return ProjectActions.createProjectSuccess({
                uuid: result.body.object_uuid,
                request_id: 'none'
              });
            } else {
              return ProjectActions.createProjectFailure({
                error: result.body
              });
            }
          }),
          catchError(error =>
            of(
              ProjectActions.createProjectFailure({
                error
              }),
              globalFailure({ error, whitelist: [404] })
            )
          )
        );
      })
    )
  );

  patchProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.patchProject),
      concatMap(action => {
        return this.middleware.patch('/v3/partners/contracts/current/projects/' + action.uuid, action.data).pipe(
          map((result: any) => {
            return ProjectActions.patchProjectSuccess({
              uuid: action.uuid,
              request_id: 'none'
            });
          }),
          catchError(error =>
            of(
              ProjectActions.patchProjectFailure({
                error,
                uuid: action.uuid
              }),
              globalFailure({ error, whitelist: [404] })
            )
          )
        );
      })
    )
  );
  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      concatMap(action => {
        return this.middleware.delete('/v3/partners/contracts/current/projects/' + action.uuid).pipe(
          map((result: any) => {
            return ProjectActions.deleteProjectSuccess({
              uuid: action.uuid,
              request_id: 'none'
            });
          }),
          catchError(error =>
            of(
              ProjectActions.deleteProjectFailure({
                error,
                uuid: action.uuid
              }),
              globalFailure({ error, whitelist: [404] })
            )
          )
        );
      })
    )
  );

  /***********
   *  Load List o Projects
   **********/

  loadProjectsForCurrentContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ContractActions.loadCurrentContractSuccess,
        ProjectActions.loadProjectsForCurrentContract,
        ProjectActions.patchProjectSuccess,
        ProjectActions.createProjectSuccess,
        ProjectActions.deleteProjectSuccess
      ),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([_action, _current_contract_uuid]) => {
        if (_current_contract_uuid) {
          return of(
            ProjectActions.loadProjects({
              contractUuid: _current_contract_uuid
            })
          );
        }
        return of(noopAction());
      })
    )
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjects),
      concatMap(action => {
        const params = new HttpParams();

        return this.middleware.get('/v3/partners/contracts/' + action.contractUuid + '/projects', params).pipe(
          map((result: any) =>
            ProjectActions.loadProjectsSuccess({
              data: result.body && result.body.projects ? result.body.projects : [],
              contractUuid: action.contractUuid
            })
          ),
          catchError(error =>
            of(
              ProjectActions.loadProjectsFailure({
                contractUuid: action.contractUuid,
                error
              }),
              globalFailure({ error })
            )
          )
        );
      })
    )
  );

  deleteProjectWithConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectWithConfirm),
      tap(_action => this.loadProject(_action.uuid)),
      switchMap(_action => this.store.select(getProject({ uuid: _action.uuid })).pipe(
        filter(project => project !== undefined),
        take(1),
        map(project => [_action, project] as [typeof _action, Project])
      )),
      concatMap(([_action, token]) =>
        this.overlayAlert.confirmDelete({
          objectType: this.translate.instant('DETAILS.OBJECT_NAMES.project'),
          objectName: token.name || '???',
          typeToConfirm: token.name,
          cannotBeUndone: true,
        }, [
          {
            label: this.translate.instant('GENERAL.CONFIRM_DELETE_KEEP'),
            keyShortcut: 'Escape',
            mode: 'secondary',
            callback: (button, alert) => {
              alert.callback.emit(false);
              alert.close();
            }
          },
          {
            label: this.translate.instant('GENERAL.CONFIRM_DELETE_DELETE', { objectType: this.translate.instant('DETAILS.OBJECT_NAMES.project') }),
            keyShortcut: 'Enter',
            mode: 'danger',
            callback: (button, alert) => {
              alert.callback.emit(true);
              alert.close();
            }
          }
        ]).pipe(
          map(result => result.confirmed
            ? ProjectActions.deleteProject({ uuid: _action.uuid })
            : noopAction()
          )
        )
      )
    )
  );

  deleteProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectSuccess),
      map(action =>
        toastAction({
          severity: 'success',
          summary: this.translate.instant('GENERAL.SUCCESSFUL'),
          detail: this.translate.instant('GENERAL.DELETE_SUCCESS_W_OBJECTNAME', { objectName: this.translate.instant('DETAILS.OBJECT_NAMES.project') })
        })
      )
    )
  );


  /**
   * ensure project is loaded into store
   */
  private loadProject(uuid: string) {
    this.store.select(isProjectLoaded({ uuid })).pipe(
      withLatestFrom(this.store.select(isProjectLoading({ uuid }))),
      take(1)
    ).subscribe(([loaded, loading]) => {
      if (!loading && !loaded) {
        this.store.dispatch(ProjectActions.loadProject({ uuid }));
      }
    });
  }

  constructor(
    private actions$: Actions,
    private middleware: MiddlewareService,
    private readonly store: Store,
    private readonly overlayAlert: OverlayAlertService,
    private readonly translate: TranslateService,
    private readonly trackingService: TrackingService
  ) {
    this.trackingService.watchObjectCRUD('project', {
      create: ProjectActions.createProject,
      patch: ProjectActions.patchProject,
      remove: ProjectActions.deleteProject
    });
  }
}
