import { concatMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomMenuActions from './custom-menu.actions';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { setLastLoginTimestamp } from '../contract/contract.actions';
import * as _ from 'lodash';

@Injectable()
export class CustomMenuEffects {
  constructor(private readonly actions$: Actions, private readonly http: HttpClient, private readonly store: Store) { }

  loadCustomMenuEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomMenuActions.getCustomMenu),
      concatMap(() =>
        this.http.get('/v2/init').pipe(
          map((result: any) => {

            this.store.dispatch(setLastLoginTimestamp({
              last_login: _.get(result, 'body.account.contract.last_login', 0)
            }));

            if (!Array.isArray(result?.body?.account?.custom_menu)) {
              return CustomMenuActions.getCustomMenuSuccess({ data: [] });
            }
            return CustomMenuActions.getCustomMenuSuccess({ data: result.body.account.custom_menu });
          }),
          catchError(error => of(CustomMenuActions.getCustomMenuFailure({ error })))
        )
      )
    )
  );
}
