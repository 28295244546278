import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, withLatestFrom, concatMap, mergeMap, tap, filter, delay, switchMap } from 'rxjs/operators';
import { Observable, of, zip, forkJoin } from 'rxjs';

import * as LimitsActions from './../../store/limits/limits.actions';
import * as LabelActions from './label.actions';


import * as _ from 'lodash';

import { ApiService, apiClient } from './../../services/api.service';
import { globalFailure, noopAction } from './../../store/common.actions';


import { Store } from '@ngrx/store';
import { getLabelListConfig, getLabelListConfigWithCurrentTotal, getLabelsLinks } from './label.selectors';



import { selectRouteNestedParam } from './../../store/router/router.selectors';




@Injectable()
export class LabelEffects {

  loadLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelActions.loadLabels),
      withLatestFrom(this.store.select(getLabelListConfig)),
      concatMap(([action, config]) =>
        this.api.$<ReturnType<typeof apiClient.Label.list>>('Label', 'list', config).pipe(
          map((result) =>
            LabelActions.loadLabelsSuccess({
              result: result.result.labels || {},
              meta: result.meta,
              links: result.links
            }),
          ),
          catchError(error => of(LabelActions.loadLabelsFailure({ error }), globalFailure({ error })))
        )
      )
    )
  );

  updateLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelActions.updateLabels),
      mergeMap(_action =>
        of(_action).pipe(
          withLatestFrom(this.store.select(getLabelListConfigWithCurrentTotal)),
        )
      ),
      concatMap(([action, config]) => {
        return this.api.$<ReturnType<typeof apiClient.Label.list>>('Label', 'list', config).pipe(
          map((result) =>
            LabelActions.updateLabelsSuccess({
              result: result.result.labels || {},
              meta: result.meta,
            }),
          ),
          catchError(error => of(LabelActions.updateLabelsFailure({ error, }), globalFailure({ error })))
        )
      }
      )
    )
  );


  loadLabelsNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LabelActions.loadLabelsNext),

      withLatestFrom(this.store.select(getLabelsLinks)),

      filter(([_, links]) => links !== undefined && links.next !== undefined),
      concatMap(([action, links]) =>
        this.api.fromFunction$(links!.next!, links).pipe(
          map((result) =>
            LabelActions.loadLabelsNextSuccess({
              result: result.result.labels || {},
              meta: result.meta,
              links: result.links
            }),
          ),
          catchError(error => of(LabelActions.loadLabelsNextFailure({ error }), globalFailure({ error })))
        )
      )
    )
  );

  watchRunning = false;

  watchProvisioningList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LabelActions.loadLabelsSuccess,
        LabelActions.loadLabelsNextSuccess
      ),
      filter(action => this.watchRunning === false && typeof (action.result) === 'object' && action.result !== null),
      delay(100),
      switchMap(action => {
        const observables: Observable<boolean>[] = [];
        _.forEach(
          _.filter(action.result, obj => obj.status !== undefined && obj.status !== 'active'),
          provisioningObj => {

            observables.push(of(false));

          }
        );

        return forkJoin([of(action), new Observable(_observer => {
          if (observables.length === 0) {
            // _observer.next((watchings).indexOf(true) >= 0);
            _observer.next(true);
            _observer.complete();
            return;
          }
          this.watchRunning = true;
          zip(...observables).subscribe(watchings => {
            _observer.next(watchings.indexOf(true) >= 0);
            _observer.complete();
          })
        }) as Observable<boolean>]);
      }),
      delay(4000),
      tap(() => this.watchRunning = false),
      map(([action, oneWatching]) => oneWatching ? noopAction() : LabelActions.loadLabels())
    )
  );



































  /**** CUSTOM EFFECTS FROM HERE ****/



  constructor(private actions$: Actions, private api: ApiService, private readonly store: Store) { }

}
