import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { NoAuthGuard } from './@shared/guards/no-auth.guard';
import { AuthGuard } from './@shared/guards/auth-guard.guard';
import { NotFoundComponent } from './system/not-found/not-found.component';
import { UserRoleGuard } from './@shared/guards/userrole.guard';
import { SignupCompleteGuard } from './@shared/guards/signup-complete.guard';
import { SignupIncompleteGuard } from './@shared/guards/signup-incomplete.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SystemErrorComponent } from './system/error/error.component';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private readonly translate: TranslateService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(this.translate.instant('GENERAL.PAGE_TITLE', { name: _.get(window, ['gs_partner_name'], '') }) + ' - ' + this.translate.instant(title));
    } else {
      this.title.setTitle(this.translate.instant('GENERAL.PAGE_TITLE', { name: _.get(window, ['gs_partner_name'], '') }));
    }
  }
}


export const appRoutes: Routes = [
  {
    path: 'Access',
    loadChildren: () => import('./auth/auth.routes').then(routes => routes.authRoutes),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'Register',
    loadChildren: () => import('./modules/register/register.routes').then(m => m.registerRoutes),
    canActivate: [AuthGuard, SignupIncompleteGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.routes').then(m => m.moduleRoutes),
    data: {
      moduleMinUserRole: 'read' // using the user role guard here, will ensure the modules will not get initialized before the user/contract is loaded
    },
    canActivate: [AuthGuard, UserRoleGuard, SignupCompleteGuard]
  },
  {
    path: 'System/Error',
    component: SystemErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  }
];

