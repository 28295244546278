import { HttpClient } from '@angular/common/http';
import { globalFailure } from './../../store/common.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as InvoiceActions from './invoice.actions';
import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class InvoiceEffects {
  constructor(private readonly action$: Actions, private readonly middleware: MiddlewareService, private readonly http: HttpClient) { }

  loadInvoices$ = createEffect(() => {
    return this.action$.pipe(
      ofType(InvoiceActions.loadInvoices),
      concatMap(action =>
        this.middleware.get(`/management/v2/contracts/current/invoices?page[size]=100&sort=-invoiceDate`).pipe(
          map((result: any) =>
            InvoiceActions.loadInvoicesSuccess({
              data: result.data
            })
          ),
          catchError(error => of(InvoiceActions.loadInvoicesFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });

  getAmount$ = createEffect(() => {
    return this.action$.pipe(
      ofType(InvoiceActions.getAmount),
      concatMap(action =>
        this.http.get(`/payment/amount`).pipe(
          map((result: any) =>
            InvoiceActions.getAmountSuccess({
              data: result.body
            })
          ),
          catchError(error => of(InvoiceActions.getAmountFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });
}
