import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { globalFailure, noopAction, toastAction } from '../common.actions';
import { selectRouteNestedParam } from '../router/router.selectors';
import * as ApplicationInstanceActions from './service_marketplace.actions';
import * as ApplicationInstanceSelectors from './service_marketplace.selectors';
import * as _ from 'lodash';
import { OverlayAlertService } from '@gridscale/ingrid/components/overlay-alert';
import { TranslateService } from '@ngx-translate/core';
import { ApiService, apiClient } from './../../services/api.service';
import { of } from 'rxjs';
import { getCurrentContractUuid, getCurrentProjectUuid } from '../session/session.selectors';
import { getCurrentProjectLocationUuid } from '../project/project.selectors';
import { MarketplaceApplicationInstanceDetailData } from '@gridscale/gsclient-js';
import { hasDirectAccessToContract } from '../contract/contract.selectors';



@Injectable()
export class ServiceMarketplaceEffects {
  loadApplicationInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadApplicationInstances),
      withLatestFrom(
        this.store.select(ApplicationInstanceSelectors.getApplicationInstanceListConfig),
        this.store.select(getCurrentProjectUuid)
      ),
      concatMap(([action, config, projectUuid]) => this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplicationInstance.listInstances>>('ServiceMarketplaceApplicationInstance', 'listInstances', projectUuid, config).pipe(
        map(result =>
          ApplicationInstanceActions.loadApplicationInstancesSuccess({
            result: result.result,
          })),
        catchError(error => of(ApplicationInstanceActions.loadApplicationInstancesFailure({ error }), globalFailure({ error })))
      ))
    )
  );



  loadApplicationInstance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadApplicationInstance),
      withLatestFrom(this.store.select(getCurrentProjectUuid)),
      filter(([action, projectUuid]) => !!projectUuid),
      concatMap(([action, projectUuid]) => this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplicationInstance.getInstance>>('ServiceMarketplaceApplicationInstance', 'getInstance', projectUuid, action.uuid).pipe(
        map(result =>
          ApplicationInstanceActions.loadApplicationInstanceSuccess({
            result: result.result,
            uuid: action.uuid
          })),
        catchError(error => of(ApplicationInstanceActions.loadApplicationInstanceFailure({ error, uuid: action.uuid }), globalFailure({ error })))
      ))
    )
  );

  loadSelectedApplicationInstance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadSelectedApplicationInstance),
      withLatestFrom(this.store.select(selectRouteNestedParam('application_instance_uuid'))),
      filter(([_action, application_instance_uuid]) => application_instance_uuid !== undefined),
      map(([_action, application_instance_uuid]) => ApplicationInstanceActions.loadApplicationInstance({ uuid: application_instance_uuid })),
    )
  );

  requestApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.requestApplication),
      withLatestFrom(
        this.store.select(getCurrentProjectUuid),
        this.store.select(getCurrentContractUuid),
        this.store.select(getCurrentProjectLocationUuid)
      ),
      concatMap(([action, projectUuid, contractUuid, locationUuid]) => this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplicationInstance.create>>('ServiceMarketplaceApplicationInstance', 'create', {
        name: action.applicationName,
        application_uuid: action.applicationUuid,
        plan_uuid: action.planUuid,
        version_uuid: action.versionUuid,
        location_uuid: locationUuid,
        contract_uuid: contractUuid,
        project_uuid: projectUuid,
        release_uuid: '5473c2c0-0ca6-441a-8d83-6b73b99d3ff8', // TODO: that should no longer be hardcode, only works for acronis ;-)
        vendor_keys: "{}",
        settings: JSON.stringify(action.planSettings)
      }).pipe(
        map(result =>
          ApplicationInstanceActions.requestApplicationSuccess({
            result: result.result,
          })),
        catchError(error => of(ApplicationInstanceActions.requestApplicationFailure({ error }), globalFailure({ error })))
      ))
    )
  );

  loadApplicationInstancesAfterUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApplicationInstanceActions.requestApplicationSuccess,
        ApplicationInstanceActions.requestDeleteApplicationInstanceSuccess
      ),
      map(action => ApplicationInstanceActions.loadApplicationInstances())
    )
  );

  loadApplicationInstanceAfterUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApplicationInstanceActions.requestDeleteApplicationInstanceSuccess
      ),
      map(action => ApplicationInstanceActions.loadApplicationInstance({ uuid: action.uuid }))
    )
  );


  requestDeleteApplicationInstance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.requestDeleteApplicationInstance, ApplicationInstanceActions.requestDeleteApplicationInstanceWithConfirm),
      tap(_action => this.loadApplicationInstance(_action.uuid)),
      switchMap(_action => this.store.select(ApplicationInstanceSelectors.getApplicationInstanceData({ uuid: _action.uuid })).pipe(
        filter(application => !!application),
        take(1),
        map(application => [_action, application] as [typeof _action, MarketplaceApplicationInstanceDetailData])
      )),
      withLatestFrom(this.store.select(getCurrentProjectUuid)),
      switchMap(([[action, application], projectUuid]) => {

        return this.overlayAlert.confirmDelete({
          objectName: application?.attributes?.name || '???',
          objectType: 'Application',
          message: this.translate.instant('SERVICE_MARKETPLACE.APPLICATION_INSTANCE_DELETE_INFO'),
          cannotBeUndone: true,
        }).pipe(
          switchMap(result => {
            if (result.confirmed) {
              return this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplicationInstance.requestDeleteInstance>>('ServiceMarketplaceApplicationInstance', 'requestDeleteInstance', projectUuid, action.uuid).pipe(
                map(result => {
                  this.store.dispatch(toastAction({
                    severity: 'success',
                    summary: this.translate.instant('SERVICE_MARKETPLACE.DELETE_REQUESTED_HEAD'),
                    detail: this.translate.instant('SERVICE_MARKETPLACE.DELETE_REQUESTED_TEXT'),
                  }));

                  return ApplicationInstanceActions.requestDeleteApplicationInstanceSuccess({
                    uuid: action.uuid
                  })
                }),
                catchError(error => of(ApplicationInstanceActions.requestDeleteApplicationInstanceFailure({ error, uuid: action.uuid }), globalFailure({ error })))
              );
            }
            return of(noopAction());
          })
        )
      })
    )
  );





  loadApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadApplications),
      withLatestFrom(
        this.store.select(hasDirectAccessToContract),
        this.store.select(getCurrentContractUuid),
      ),
      concatMap(([action, directContractAccess, contractUuid]) => {
        let obs = this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplication.catalog>>('ServiceMarketplaceApplication', 'catalog');

        if (!directContractAccess && contractUuid) {
          obs = this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplication.catalogForAccount>>('ServiceMarketplaceApplication', 'catalogForAccount', contractUuid);
        }


        return obs.pipe(
          map(result =>
            ApplicationInstanceActions.loadApplicationsSuccess({
              result: result.result
            })),
          catchError(error => of(ApplicationInstanceActions.loadApplicationsFailure({ error }), globalFailure({ error })))
        );
      }
      ))
  );

  loadApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadApplication),
      concatMap(action => this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceApplication.get>>('ServiceMarketplaceApplication', 'get', action.uuid).pipe(
        map(result =>
          ApplicationInstanceActions.loadApplicationSuccess({
            result: result.result,
            uuid: action.uuid
          })),
        catchError(error => of(ApplicationInstanceActions.loadApplicationFailure({ error, uuid: action.uuid }), globalFailure({ error })))
      )
      ))
  );

  loadSelectedApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadSelectedApplication),
      withLatestFrom(this.store.select(selectRouteNestedParam('application_uuid'))),
      filter(([_action, application_uuid]) => application_uuid !== undefined),
      map(([_action, application_uuid]) => ApplicationInstanceActions.loadApplication({ uuid: application_uuid })),
    )
  );


  loadPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationInstanceActions.loadPlan),
      concatMap(action => this.api.$<ReturnType<typeof apiClient.ServiceMarketplacePlan.get>>('ServiceMarketplacePlan', 'get', action.planUuid).pipe(
        map(result =>
          ApplicationInstanceActions.loadPlanSuccess({
            result: result.result,
            planUuid: action.planUuid
          })),
        catchError(error => of(ApplicationInstanceActions.loadPlanFailure({ error, planUuid: action.planUuid }), globalFailure({ error })))
      )
      ))
  );

  loadVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApplicationInstanceActions.loadVersion
      ),
      concatMap(action => this.api.$<ReturnType<typeof apiClient.ServiceMarketplaceVersion.get>>('ServiceMarketplaceVersion', 'get', action.uuid).pipe(
        map(result =>
          ApplicationInstanceActions.loadVersionSuccess({
            result: result.result,
            uuid: action.uuid
          })),
        catchError(error => of(ApplicationInstanceActions.loadVersionFailure({ error, uuid: action.uuid }), globalFailure({ error })))
      )
      ))
  );

  /**
    * ensure applicationInstance is loaded into store
    */
  private loadApplicationInstance(uuid: string) {
    this.store.select(ApplicationInstanceSelectors.isApplicationInstanceLoaded({ uuid })).pipe(
      withLatestFrom(this.store.select(ApplicationInstanceSelectors.isApplicationInstanceLoading({ uuid }))),
      take(1)
    ).subscribe(([loaded, loading]) => {
      if (!loading && !loaded) {
        this.store.dispatch(ApplicationInstanceActions.loadApplicationInstance({ uuid }));
      }
    });
  }


  constructor(
    private api: ApiService,
    private actions$: Actions,
    private readonly store: Store,
    private readonly overlayAlert: OverlayAlertService,
    private readonly translate: TranslateService
  ) { }
}
